<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import General from "@/services/General";
import modalAddSecurityGroupRules from "@/components/modals/general/modalAddSecurityGroupRules";
import Swal from "sweetalert2";

export default {
    components: { Layout, PageHeader, modalAddSecurityGroupRules },
    data() {
        return {
            title: "Security Group Rules",
            error: null,
            tableData: [],
            currentPage: 1,
            perPage: 10,
            items: [
                {
                    text: "AWS",
                },
                {
                    text: "Security Group Rules",
                    active: true,
                },
            ] ,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "created_at",
            sortDesc: true,
            isBusy: false,
            roles : [],
            fields: [
                {
                    key: "GroupId",
                    label: "Group Id",
                    class: "text-nowrap",
                    sortable: false,
                },
                {
                    key: "CidrIp",
                    label: "CIDR Ip Range",
                    class: "text-nowrap",
                    sortable: true,
                },
                {
                    key: "Description",
                    label: "Description",
                    sortable: false
                },
                {
                    key: "created_at",
                    label: "Created At",
                    sortable: true
                },
                "action",
            ],
            totalRows: 0,
            showModal: false,
            securityGroupData:[]
        };
    },
    async mounted() {
        await this.getSecurityGroupRules()
    },
    methods: {
        async getSecurityGroupRules(GroupID) {
            try {
                this.isBusy = true;
                const response = await General.getSecurityGroupRules();
                this.originalTableData = response.data.data;
                this.tableData = [...this.originalTableData];
                this.totalRows = this.tableData.length;

            } catch (error) {
                this.error = error.response.data.error ? error.response.data.error : "";
                this.tableData = [];
                this.totalRows = 0;
            } finally {
                this.showLoader = false;
                this.isBusy = false;
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },

        deleteSecurityRule(CidrIp){

            Swal.fire({
                title: "Delete Rule For Range <br>" + CidrIp,
                icon: "warning",
                html: `
                        <p>Type "Delete" to confirm:</p>
                        <input type="text" id="deleteConfirmation" class="swal2-input" autocomplete="off">
                    `,
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Delete",
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const confirmationText = Swal.getPopup().querySelector(
                        "#deleteConfirmation"
                    ).value;
                    if (confirmationText.toLowerCase() !== "delete") {
                        Swal.showValidationMessage('You must type "Delete" to confirm');
                        return false;
                    }
                    return General.removeTenantSecurityGroupRule({
                        'CidrIp': CidrIp
                    })
                        .then((response) => {
                            return response;
                        })
                        .catch((error) => {
                            let errorMsg = error.response.data.error
                                ? error.response.data.error
                                : "";
                            this.failedmsg("Fail!", errorMsg);
                        });
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    const res = result.value.data ? result.value.data.success : false;
                    const error = result.value.data.error
                        ? result.value.data.error
                        : "Failed";

                    if (res) {
                        this.successmsg('Delete Security Group Rule', 'Rule is successfully deleted')
                        setTimeout(() => {
                            this.getSecurityGroupRules();
                        }, 2000);
                    } else {
                        this.warningmsg("Fail!", error);
                    }
                }
            });
        },

        callModalCreateSecurityGroupRule(){
            this.$bvModal.show("addSecurityGroupRules");
        },

    },
    middleware: "authentication",
};
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <h5><i class="uil uil-info-circle text-primary"></i> IP Address Ranges for Dashboard Accessibility</h5>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-3">
                                <div class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>

                            <div class="col-sm-12 col-md-9">
                                <b-button class="float-end" variant="primary" @click="callModalCreateSecurityGroupRule()">Create New Rule</b-button>
                            </div>
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    :busy="isBusy"
                                    :items="tableData"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    show-empty
                                    empty-text="No Data Found"
                                    @filtered="onFiltered"
                            >

                                <template v-slot:cell(action)="data">
                                    <ul class="list-inline mb-0">
                                        <li class="list-inline-item">
                                            <a
                                                href="javascript:void(0);"
                                                class="px-2 text-danger"
                                                title="Delete Security Group Rule"
                                                @click="deleteSecurityRule(data.item.CidrIp)" >
                                                <i class="uil uil-trash-alt font-size-18"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                                <template #table-busy>
                                    <div class="text-center text-danger my-2">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="dataTables_paginate paging_simple_numbers float-end">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modalAddSecurityGroupRules
                @onRefresh="getSecurityGroupRules"
        ></modalAddSecurityGroupRules>

    </Layout>
</template>
